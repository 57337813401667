<template>
  <section id="instalaciones" class="installations-section" data-aos="fade-up">
    <div class="centered-wrapper sketch-section">
      <div class="sketch-controls">
        <p class="section-title">
        Nuestras instalaciones
      </p>

      <p class="section-subtitle lowercase">
         DODIT está situada en el Distrito Digital de Alicante. La empresa tiene como objetivo crear contenidos interactivos educativos y potenciar el ecosistema regional mediante la gestión de ayudas e inversión a proyectos innovadores. Las instalaciones, de más de 3.000m2, cuentan con oficinas, sala de runiones, salas de exposición, sala de streaming y 2 platos de cine completamente equipados para realizar contenidos audiovisuales.
      </p>
        <div class="sketch-buttons">
          <button class="btn" :class="this.currentFloor == 'floor1' ? 'active' : null"  @click="changeFloor(1)">Planta 1</button>
          <button class="btn" :class="this.currentFloor == 'floor2' ? 'active' : null"  @click="changeFloor(2)">Planta 2</button>
        </div>
      </div>
      <transition name="slide-down" mode="out-in">
      
      <img 
      key="floor1"
      v-if="this.currentFloor == 'floor1'"
      :class="this.currentFloor == 'floor1' ? 'active' : null" 
      src="~@/assets/img/planta1.svg" alt=""
      >

      <img 
      key="floor2"
      v-if="this.currentFloor == 'floor2'" 
      :class="this.currentFloor == 'floor2' ? 'active' : null" 
      src="~@/assets/img/planta2.svg" alt=""
      >
      </transition>
      <!-- <iframe src="" id="api-frame" sandbox="allow-scripts allow-same-origin allow-popups allow-forms" allow="autoplay; fullscreen; vr" allowvr allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" width="100%" height="100%"></iframe> -->
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    changeFloor: function(floor) {
      if(floor == 1) {
        this.currentFloor = 'floor1'
      } else {
        this.currentFloor = 'floor2'
      }
    }
  },
  data() {
    return {
      currentFloor: 'floor1'
    }
  }
}

</script>

<style>

</style>