<template>
  <div class="hero">

    <svg id="heroDecor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4666.9 4666.9">

      <path id="outter" fill="#F9423A" d="M2333.5 4666.9L0 2333.5 2333.5 0l106.1 106.1L4667 2333.5 2333.5 4666.9zM424.3 2333.5l1909.2 1909.2 1909.2-1909.2L2333.5 424.3 424.3 2333.5z"/>
  <path id="inner" fill="#F9423A" d="M2333.5 3959.8L707.1 2333.5l106.1-106.1L2333.5 707.1l1626.3 1626.3-1626.3 1626.4zM1131.4 2333.5l1202.1 1202.1 1202.1-1202.1-1202.1-1202.1-1202.1 1202.1z"/>
  <!-- <path @click="scrollDown()" id="arrow" fill="#F9423A" d="M2555.4 3206.2h-443.9l219.2 224.6 224.7-224.6z"/> -->
    </svg>

  <div>  
    <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207.4 147.6">
      <path fill="none" stroke="#F9423A" class="st0" d="M111.6.5V146.4l73-73-41.7-41.6v22.1l19.6 19.5-35.2 35.2V16.1L111.6.5M184.6 95.618l11.032-11.03 10.96 10.96-11.031 11.03zM95.9 146.3V.5h-.1L22.9 73.4l41.7 41.7V93L45 73.4l35.2-35.2v92.5l15.6 15.6M.699 95.612l11.03-11.031 10.96 10.96-11.03 11.03z"/>
    </svg>
    <!-- <a @click="scrollDown()">
      <img src="~@/assets/img/scroll-down.svg" id="arrow">
    </a> -->
  </div>

    <div @click="scrollDown()" class="scroll-down">
      <div class="mouse">
        <div class="scroll-wheel"></div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  methods: {
    scrollDown: function(){
      let headerOffset = -100;
      let y = document.querySelector('#service-list').getBoundingClientRect().top + window.pageYOffset + headerOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  },
}
</script>

<style>

</style>