<template>
  <section id="proyectos" class="project-section">

    <div class="centered-wrapper" data-aos="fade-up">
          <div class="section-header">
      <p class="section-title">
        NUESTROS PROYECTOS
      </p>
      <div class="select-wrapper">
        <select v-model="tag" name="project-filter" id="project-filter">
          <option value="">Todos los proyectos</option>
          <option value="esports">Esports</option>
          <!-- <option value="formacion">Formación</option> -->
          <option value="servicios">Servicios</option>
        </select>
      </div>
    </div>

    <div v-if="filterProjects != ''" class="project-list">
      <article v-for="project in filterProjects" v-bind:key="project.title">
        <div data-aspect-ratio="3:1" class="project-image">
          <div class="content" :style="{backgroundImage: `url(${project.bg})`}">
            <img v-if="project.logo" :src="project.logo" alt="">
            <div class="tag">
              {{project.tag}}
            </div>
          </div>
        </div>
        <h1>
          {{project.title}}
        </h1>
        <h2>
          {{project.content}}
        </h2>

        <a v-if="project.url" :href="project.url" target="_blank">Ver proyecto <img src="~@/assets/img/arrow.svg" alt=""> </a>
        
      </article>
    </div>
    <div v-else>
      asd
    </div>
    </div>
  <img class="projects-decor rellax" src="~@/assets/img/projects/projects-decor.svg" alt="">
  </section>
</template>

<script>
export default {
data() {
  return {
    publicPath: process.env.BASE_URL,
    tag: '',
    projects: [
      {
        title: "Amazon University Esports",
        content: "Competición con 6 modalidades de Esports en la que compiten más de 400 Universidades alrededor de Europa y Latinoamérica. El proyecto reúne a más de 30.000 jugadores y jugadoras que compiten a nivel nacional con el fin de representar a su país y universidad y ganar los más de 80.000€ en becas y premios.",
        bg: require("@/assets/img/projects/university-bg.jpg"),
        logo: require("@/assets/img/projects/university-logo.png"),
        tag: "esports",
        url: "https://universityesports.es/"
      },
      {
        title: "JUNIOR Esports",
        content: "La primera competición de deportes electrónicos entre centros educativos en España para alumnos y alumnas de 14 y 18 años. Actualmente en la liga nacional participan más de 535 centros, 577 profesores y profesoras, y más de 5.000 alumnos y alumnas que podrán optar por un premio de 20.000€ en material informático.",
        bg: require("@/assets/img/projects/iesports-bg.jpg"),
        logo: require("@/assets/img/projects/iesports-logo.png"),
        tag: "esports",
        url: "https://iesports.es/"
      },
      {
        title: "Circuito Tormenta",
        content: "Formato de competición cuyo objetivo es conectar todos los eventos presenciales de Esports y Gaming en España. Cada uno de los eventos que forma parte del Circuito aportará puntos a los equipos para poder escalar en el ranking nacional. Está formado por más de 20 eventos, +3.000 equipos y +15.000 jugadores y jugadoras.",
        bg: require("@/assets/img/projects/circuito-tormenta-bg.jpg"),
        logo: require("@/assets/img/projects/circuito-tormenta-logo.png"),
        tag: "esports",
        url: "https://circuitotormenta.com/"
      },
      {
        title: "University Esports Masters",
        content: "Liga de deportes electrónicos internacional en la que compiten las mejores universidades de cada país que disputan el título de campeones en Europa. La liga cuenta con más de 12 equipos, uno por cada país de las más de 800 universidades que participan en las competiciones nacionales.",
        bg: require("@/assets/img/projects/uem-bg.jpg"),
        logo: require("@/assets/img/projects/uem-logo.png"),
        tag: "esports",
        url: "https://uemasters.com/"
      },
      {
        title: "Liga Rúnica",
        content: "Máxima competición profesional de los títulos Legends of Runeterra y Teamfight Tactics donde jugadores y jugadoras de todo el país pueden luchar por ser el mejor jugador o jugadora nacional de cada título. En esta liga participan los 8 mejores jugadores y jugadoras de nuestro país y compiten por el prize pool de 1.000€ en 7 jornadas retransmitidas en directo.",
        bg: require("@/assets/img/projects/liga-runica-bg.jpg"),
        logo: require("@/assets/img/projects/liga-runica-logo.png"),
        tag: "esports",
        url: "https://ligarunica.com/"
      },
      {
        title: "GAMERGY WORLD",
        content: "Generación de contenido adaptado a marcas e instituciones realizado para el mundo virtual de GY World, contenido desarrollado usando eWorlds Engine un motor de GGTech Entertainment.",
        bg: require("@/assets/img/projects/gy-world-bg.png"),
        logo: require("@/assets/img/projects/gy-world-logo.png"),
        tag: "servicios",
        url: 'https://gamergyworld.es/'
      },
    ]
  }
},
computed: {
    filterProjects: function(){
        return this.projects.filter(project => !project.tag.indexOf(this.tag))
    }
}
}
</script>

<style>

</style>