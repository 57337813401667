<template>
    <div>
        <Hero />
        <MainHeader />
        <Services />
        <div class="red-band">
            <Proyects />
            <Partners />
            <Installations />
        </div>
        <MainFooter />
    </div>
</template>

<script>
import Rellax from 'rellax';
import AOS from 'aos';
import 'aos/dist/aos.css';
import MainHeader from '../components/MainHeader.vue'
import Proyects from '../components/Proyects.vue'
import Services from '../components/Services.vue'
import Partners from '../components/Partners.vue'
import Installations from '../components/Installations.vue'
import MainFooter from '../components/MainFooter.vue'
import Hero from '../components/Hero.vue'

export default {
    // name: 'App',
    components: {
        MainHeader,
        Hero,
        Services,
        Proyects,
        Partners,
        Installations,
        MainFooter
    },
    methods: {
        scroll () {
            window.onscroll = () => {
                let headerOffset = document.querySelector('.hero').offsetTop + document.querySelector('.hero').offsetHeight;
                
                // let heroBottom = document.querySelector('.hero').offsetTop + document.querySelector('.hero').offsetHeight;
                let headerVisible = window.pageYOffset >= headerOffset;
                
                document.querySelector('.main-header').style.setProperty('--header-offset', headerOffset + "px");
                
                if (headerVisible) {
                    document.querySelector('.main-header').classList.add('visible');
                } else {
                    document.querySelector('.main-header').classList.remove('visible');
                }
            }
        },
    },
    mounted() {
        Rellax('.rellax');
        //  this.scroll();
        AOS.init({
            offset: 100
        });
    },
}
</script>

