<template>
  <div>

    <section id="service-list" class="service-list" >
    <div class="centered-wrapper" data-aos="fade-up">
    <div v-for="service in services" v-bind:key="service.title" class="service-item" style="background-image: url('~@/assets/img/services/test.jpg');">
      <img :src="service.image" :alt="service.title">
      <div class="service-item--content">
        <p class="service-item--title">
        {{service.title}}
      </p>
      <p class="service-item--desc">
        {{service.desc}}
      </p>
      </div>
      </div>
    </div>
  <img class="services-decor rellax" data-rellax-speed="2" src="~@/assets/img/services/services-decor.svg" alt="">

  </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          title: "Esports",
          desc: "Gestión integral de competiciones de videojuegos. Producción audiovisual, eventos presenciales y online.",
          image: require("@/assets/img/services/esports.png"),
        },
        {
          title: "Formación",
          desc: "Contamos con docentes expertos y expertas en el desarrollo de productos interactivos trabajando en un máster de desarrollo de videojuegos.",
          image: require("@/assets/img/services/formacion.png"),
        },
        {
          title: "Desarrollo",
          desc: "Aplicaciones multiplataforma, Serious Games y proyectos de innovación en las principales tecnológicas punteras como AR y VR ",
          image: require("@/assets/img/services/desarrollo.png"),
        },
        {
          title: "CONTENIDOS",
          desc: "Ofrecemos servicios profesionales en organización de eventos y generación de contenidos relacionados con el mundo digital.",
          image: require("@/assets/img/services/hub.png"),
        }
      ]
    }
  },
}
</script>

<style>

</style>