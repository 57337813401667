<template>
  <header class="main-header" :class="navVisible ? 'active' : null">
    <div class="centered-wrapper">
      <div class="logo">
        <router-link to="/">
          <img src="~@/assets/img/dodit-svg.svg" alt="">
        </router-link>
      </div>
      <nav :class="navVisible ? 'active' : null">
        <ul>
          <li v-for="item in navigation" v-bind:key="item.label">
            <a @click="moveAndClose(item.target)"><span>{{item.label}}</span></a>
          </li>
        </ul>
      </nav>
      <img @click="toggleNav" class="toggle-nav" :class="navVisible ? 'active' : null" src="~@/assets/img/toggle-nav.svg" alt="">
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  mounted() {
    console.log(this.$router.history.current.path)
  },
  methods: {
    toggleNav: function() {
      let headerOffset = -100;
      let y = document.querySelector('#service-list').getBoundingClientRect().top + window.pageYOffset + headerOffset;
      if(window.pageYOffset <= y) {
        window.scrollTo({top: y, behavior: 'smooth'});
      }
      this.navVisible = !this.navVisible;
    },
    moveAndClose: function(target) {
      let headerOffset = -100;
      let y = document.querySelector(target).getBoundingClientRect().top + window.pageYOffset + headerOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
      // console.log(target);
      this.navVisible = false
    },
    navigationElements: function(route){
      if (route == '/') {
        return [
        {
          label: "Servicios",
          target: "#service-list"
        },
        {
          label: "Proyectos",
          target: "#proyectos"
        },
        {
          label: "marcas",
          target: "#marcas"
        },
        {
          label: "Instalaciones",
          target: "#instalaciones"
        },
        {
          label: "contacto",
          target: "#contacto"
        },
        ]
      } else {
        return []
      }
    }
  },
  data() {
    return {
      navVisible: false,
      navigation: this.navigationElements(this.$router.history.current.path)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
