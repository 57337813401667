<template>
  <div  id="marcas">
      <section class="partners-section centered-wrapper" data-aos="fade-up">
    <p class="section-title dark">
      CONFÍAN EN NUESTROS PROYECTOS
    </p>
    <p class="section-subtitle lowercase limit-width center dark">
      Marcas líderes a nivel mundial han depositado su confianza en nosotros para poder gestionar, desarrollar y llevar a cabo sus proyectos en el ámbito de videojuegos y formación.
    </p>
    <div class="partner-list">
      <img 
      v-for="sponsor in sponsors" 
      v-bind:key="sponsor.title"
      :src="sponsor.logo" 
      :alt="sponsor.title">
    </div>
  </section>
  <img class="sponsors-decor rellax" src="~@/assets/img/sponsors/sponsors-decor.svg" alt="">
  </div>
</template>

<script>
export default {
data() {
  return {
    sponsors: [
      // {
      //   title: "Riot Games",
      //   logo: require("@/assets/img/sponsors/riot-games.svg"),
      // },
      {
        title: "Gamergy",
        logo: require("@/assets/img/sponsors/gamergy.svg"),
      },
      {
        title: "Ifema",
        logo: require("@/assets/img/sponsors/ifema.svg"),
      },
      {
        title: "Supercell",
        logo: require("@/assets/img/sponsors/supercell.svg"),
      },
      // {
      //   title: "Tencent",
      //   logo: require("@/assets/img/sponsors/tencent.svg"),
      // },
      // {
      //   title: "Epic Games",
      //   logo: require("@/assets/img/sponsors/epic-games.svg"),
      // },
      // {
      //   title: "Ubisoft",
      //   logo: require("@/assets/img/sponsors/ubisoft.svg"),
      // },
      {
        title: "Amazon",
        logo: require("@/assets/img/sponsors/amazon.svg"),
      },
      {
        title: "Carrefour",
        logo: require("@/assets/img/sponsors/carrefour.svg"),
      },
      {
        title: "Intel",
        logo: require("@/assets/img/sponsors/intel.svg"),
      },
      {
        title: "Omen",
        logo: require("@/assets/img/sponsors/omen.svg"),
      },
      // {
      //   title: "Telcel",
      //   logo: require("@/assets/img/sponsors/telcel.svg"),
      // },
      {
        title: "Twitch",
        logo: require("@/assets/img/sponsors/twitch.svg"),
      },
      {
        title: "Mapfre",
        logo: require("@/assets/img/sponsors/mapfre.svg"),
      },
      {
        title: "Logitech",
        logo: require("@/assets/img/sponsors/logitech.svg"),
      },
      {
        title: "McDonals",
        logo: require("@/assets/img/sponsors/mcdonals.svg"),
      },
      // {
      //   title: "Iecisa",
      //   logo: require("@/assets/img/sponsors/iecisa.svg"),
      // },
    ]
  }

},
}
</script>

<style>

</style>