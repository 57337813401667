<template>
  <footer id="contacto" class="main-footer">
    <div class="centered-wrapper">
      <section id="servicios" data-aos="fade-down" class="project-intro">
        <h1 class="center dots">Somos dodit</h1>
        <h2>
          DODIT es una empresa tecnológica con proyectos vinculados al sector del videojuego y de la educación. Originada gracias a la unión estratégica de dos compañías, GGTech Entertainment y Sociedad Proyectos Temáticos de la Comunidad Valenciana S.A. (SPTCV) Con esta empresa se establece un centro de innovación a nivel nacional e internacional en los ámbitos descritos con su centro neurálgico en Alicante.
        </h2>
      </section>
    </div>
    <div class="centered-wrapper">
      
      <div class="group">
        <img src="~@/assets/img/group/gvdd.png" alt="Distrito Digital">
        <img src="~@/assets/img/group/ggtech.svg" alt="GGTech">
        <!-- <img src="~@/assets/img/group/genval.png" alt="Generalitat Valenciana"> -->
        <!-- <img src="~@/assets/img/group/dd.png" alt="Distrito Digital"> -->
        <img src="~@/assets/img/group/sptcv.png" alt="sptcv">
      </div>
      
      <div class="contact">
        <div class="copy">
          <img src="~@/assets/img/dodit-svg.svg" alt="Dodit">
          <p>
            <small>
              &copy; 2023 Dodit, todos los derechos reservados.
            </small>
          </p>
        </div>
        <div class="contact-list">
          <ul>
            <li> <a href="mailto:info@dodit.es">info@dodit.es</a> </li>
            <li>Avinguda de Jean Claude Combaldieu, Alicante, España</li>
          </ul>
        </div>
        <div class="social">
          <!-- <a href="">
            <i class="fab fa-linkedin-in"></i>
          </a> -->
        </div>
      </div>
      
      <div class="extra-links">
        <router-link to="/ethic">Canal ético</router-link>
        <a href="https://s3.eu-west-1.amazonaws.com/2023.ggtech.gg/privacy/aviso-legal-dodit.pdf" target="_blank">Aviso legal</a>
      </div>
      
      
    </div>
  </footer>
</template>

<script>
export default {
  
}
</script>

<style>

</style>